import { Component } from '@angular/core';
import { ModalComponent } from './modal.component';

@Component({
	selector: 'app-modal-footer',
	template: `
		<footer class="p-5 flex justify-end  items-center border-t border-t-secondary">
			<ng-content></ng-content>
		</footer>
	`,
	styleUrls: ['./modal.component.scss']
})
export class ModalFooterComponent {

	constructor(readonly modal: ModalComponent) { }

}
