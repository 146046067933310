import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

	class: string = '';
	isAnchor: boolean = false;


	constructor(private elem: ElementRef) { }

	@Input() text: string = '';
	@Input() type: string = 'button';
	@Input() size: string = 'default';
	@Input() color: string = 'primary';
	@Input() style: string = 'default';
	@Input() isIconLeft: boolean = false;
	@Input() iconLeft: any;
	@Input() isIconRight: boolean = false;
	@Input() iconRight: any;
	@Input() iconClass: string = '';
	@Input() isDisabled: boolean = false;
	@Input() link = '';
	@Input() width: string = '';
	@Output() btnClick = new EventEmitter();

	ngOnInit(): void {
		const class_arr: string[] = ['rounded'];
		let color: string = 'primary';
		let hover_color: string = 'primary-light';
		let display: string = 'inline-block'

		if (this.link !== '') {
			this.isAnchor = true;
		}

		if (this.width == 'full') {
			display = 'block';
		}

		if (this.color != color) {

		}

		if (this.isAnchor) {
			class_arr.push(display);
			if (this.isDisabled) {
				this.elem.nativeElement.classList.add('cursor-not-allowed');
				class_arr.push('pointer-events-none')
			}
		}

		switch (this.style) {
			case 'outline':
				if (this.isAnchor && this.isDisabled) {
					class_arr.push('border-solid', 'border-2', 'border-primary-dark', 'text-primary-dark');
				} else {
					class_arr.push('text-primary', 'border-solid', 'border-2', `border-${color}`,
						`hover:border-${hover_color}`, `hover:text-${hover_color}`, 'disabled:border-primary-dark', 'disabled:text-primary-dark', 'disabled:cursor-not-allowed');
				}
				break;
			case 'text':
				class_arr.push('border-solid', 'border-2', 'border-transparent', 'text-primary', 'hover:border-secondary', 'hover:bg-secondary');

				break;
			default:
				if (this.isAnchor && this.isDisabled) {
					class_arr.push('bg-primary-dark', 'text-primary-lighter', 'border-2', 'border-primary-dark');
				} else {
					class_arr.push('text-white', `bg-${color}`, `hover:bg-${hover_color}`,
						'disabled:bg-primary-dark', 'disabled:text-primary-lighter', 'disabled:cursor-not-allowed', 'border-2', `border-${color}`, `hover:border-${hover_color}`, `disabled:border-primary-dark`);
				}
		}

		switch (this.size) {
			case 'small':
				class_arr.push('px-2', 'py-1.5');
				break;
			default:
				class_arr.push('font-semibold', 'px-3', 'py-2');
		}

		// console.log(class_arr);
		this.class = class_arr.join(' ');
		// console.log(this.class);
	}

	onClick() {
		this.btnClick.emit();
	}

}