<table cdk-table class="w-full text-sm text-left text-primary relative" [dataSource]="dataSource">
	<div class="absolute opacity-50 bg-black left-0 top-0 bottom-0 right-0">

	</div>
	<ng-container *ngFor="let disCol of tableColumns; let colIndex = index;" [cdkColumnDef]="disCol.columnDef">
		<th cdk-header-cell *cdkHeaderCellDef class="text-xs text-gray-700 uppercase bg-gray px-6 py-3" (click)="sort(disCol.columnDef)">
			{{ disCol.header }}
			<span class="ml-2" *ngIf="disCol?.isSortable">
				<fa-icon [icon]="['fas', sortIcon[sortColumns[disCol.columnDef]]]"></fa-icon>
			</span>
		</th>
		<td cdk-cell *cdkCellDef="let row" class="px-6 py-4">
			<span *ngIf="!disCol.isLink; else link">
				{{ row[disCol.columnDef] }}
			</span>
			<ng-template #link>
				<a class="font-semibold hover:underline" *ngFor="let link of row[disCol.columnDef]" [routerLink]="link.url">
					{{ link.name }}
				</a>
			</ng-template>
		</td>
	</ng-container>

	<tr class="bg-white border-b border-b-secondary" *cdkNoDataRow>
		<td class="text-xs text-gray-700 uppercase px-6 py-3 text-center" [attr.colspan]="getDisplayedColumns().length">No data</td>
	</tr>

	<tr cdk-header-row *cdkHeaderRowDef="getDisplayedColumns()"></tr>

	<tr class="bg-white border-b border-b-secondary hover:bg-secondary" cdk-row *cdkRowDef="let row; columns: getDisplayedColumns();">
	</tr>
</table>