import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Store, Select } from '@ngxs/store';


import { Chart } from 'chart.js/auto';

import { ApiService } from '../shared/services/api/api.service';

import { environment } from '@env/environment';

import { GatewaysEvents } from '../shared/state/gateways_events/gateways_events.actions';
import { GatewaysEventsState } from '../shared/state/gateways_events/gateways_events.state';
import { Event } from '../shared/models/event/event.model';
import { OrganizationState } from '../shared/state/organization/organization.state';
import { Organization } from '../shared/state/organization/organization.model';
import { Organization as OrganizationAction } from '../shared/state/organization/organization.actions';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	public chart: any;
	public chart2: any;

	public organization: any;

	public $gatewaysEventsTableData: Observable<any>;
	public gatewaysEventsTableColumns: any;

	public filter: any;

	public term: string;


	@Select(GatewaysEventsState.selectGatewayEvents) $gatewaysEvents: Observable<Event[]>;
	@Select(GatewaysEventsState.filter) $filter: Observable<any>;

	@Select(OrganizationState.selectOrganizations) $organization: Observable<Organization[]>;


	constructor(private apiService: ApiService, private store: Store) { }

	ngOnInit(): void {

		this.store.dispatch(new GatewaysEvents.SetFilter({ per_page: 10, page: 1, q: '', total: '' }))
		this.store.dispatch(new GatewaysEvents.FetchAll);

		this.store.dispatch(new OrganizationAction.FetchAll);

		this.$filter.subscribe((data) => {
			this.filter = data;
		});

		this.gatewaysEventsTableColumns = [
			{
				columnDef: 'gateway',
				header: 'Gateway',
				isSortable: true,
				isLink: true
			},
			{
				columnDef: 'device',
				header: 'Device',
				isSortable: true,
				islink: true
			},
			{
				columnDef: 'old_value',
				header: 'Old Value',
				isSortable: true
			},
			{
				columnDef: 'new_value',
				header: 'New Value',
				isSortable: true
			},
			{
				columnDef: 'type',
				header: 'Type',
				isSortable: true
			},
			{
				columnDef: 'created_at',
				header: 'Created at date',
				isSortable: true
			}
		];

		this.$gatewaysEvents.subscribe((events: Event[]) => {

			this.$gatewaysEventsTableData = events.map((d: Event) => {
				return {
					gateway: [{
						url: `/gateways/${d.gateway.id}`,
						name: d.gateway.name ? d.gateway.name : d.gateway.third_party.name
					}],
					device: d.device ? d.device.third_party.name : '-',
					new_value: d.new_value ?? '-',
					old_value: d.old_value ?? '-',
					type: d.type ? `${d.type.target}_${d.type.event}` : '-',
					created_at: d.created_at
				}
			}) as any;
		});


		this.$organization.subscribe((organizations: Organization[]) => {
			const organization = organizations[0];

			this.organization = organization;
			if (!this.organization) return;

			console.log('this.organization', this.organization);
			const chartData: any = {
				type: 'doughnut',
				data: {
					labels: [
						'Offline',
						'Online'
					],
					datasets: [{
						data: [this.organization.statistics.gateways.total - this.organization.statistics.gateways.online, this.organization.statistics.gateways.online],
						backgroundColor: [
							'rgb(255, 0, 0)',
							'rgb(0, 255, 0)'
						],
						hoverOffset: 4
					}]
				},
				options: {
					cutout: '60%',
					responsive: true,
					aspectRatio: 1,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					}
				}
			};

			if (this.chart) {
				this.chart.clear();
				this.chart.destroy();
				this.chart = null;
			}

			this.chart = new Chart('online_state_chart', chartData);

			const deviceOnlineStateChartData: any = {
				type: 'doughnut',
				data: {
					labels: [
						'Offline',
						'Online'
					],
					datasets: [{
						data: [this.organization.statistics.devices.total - this.organization.statistics.devices.online, this.organization.statistics.devices.online],
						backgroundColor: [
							'rgb(255, 0, 0)',
							'rgb(0, 255, 0)'
						],
						hoverOffset: 4
					}]
				},
				options: {
					cutout: '60%',
					responsive: true,
					aspectRatio: 1,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					}
				}
			};

			if (this.chart2) {
				this.chart2.clear();
				this.chart2.destroy();
				this.chart2 = null;
			}

			this.chart2 = new Chart('device_online_state_chart', deviceOnlineStateChartData);
		});
	}

	onSearchEvent() {
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, q: this.term }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	pageChange(pageNumber: any) {
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, page: pageNumber }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	perPageChange(perPageNumber: any) {
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, per_page: perPageNumber }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	sortChanged(sorting: any) {
		const s = Object.entries(sorting).filter((e: any) => e[1] !== 'default').map((e: any) => e.join('.'));
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, s: s }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

}
