import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

	@Input() id: string = '';
	@Input() noCloseOnBackdrop: boolean = false;
	@Input() noCloseButton: boolean = false;
	private element: any;

	constructor(private modalService: ModalService, private el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngOnInit(): void {
		// ensure id attribute exists
		if (!this.id) {
			console.error('modal must have an id');
			return;
		}

		// modals are hidden by default
		this.element.classList.add('hidden');

		// move element to bottom of page (just before </body>) so it can be displayed above everything else
		document.body.appendChild(this.element);

		// close modal on background click
		this.element.addEventListener('click', (el: any) => {
			if (this.noCloseOnBackdrop) return;

			if (typeof el.target.className == 'string' && el.target.className.includes('modal')) {
				this.close();
			}
		});

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modalService.add(this);
	}

	// remove self from modal service when component is destroyed
	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		this.element.remove();
	}

	// open modal
	open(): void {
		console.log(this.id, 'open');
		this.element.classList.remove('hidden')
		document.body.classList.add('overflow-hidden');
	}

	// close modal
	close(): void {
		console.log(this.id, 'close');
		this.element.classList.add('hidden');
		document.body.classList.remove('overflow-hidden');
	}



}
