<div>
	<div class="flex mb-2">
		<div>
			<app-button [style]="buttonStyle(filterButtonStyleState)" text="Filter" (btnClick)="toggleFilter()"></app-button>
		</div>
		<!-- <div class="ml-auto">
			<app-button [style]="buttonStyle(exportButtonStyleState)" text="Export"></app-button>
		</div> -->
	</div>
	<div class="rounded-lg bg-gray p-3 mb-5">
		<div class="flex flex-col gap-2">
			<div class="flex gap-3 items-center ">
				Filter options
			</div>
			<div class="flex gap-3 items-center ">
				<div class="flex-grow">
					<select
						class="block bg-white border-2 border-secondary text-primary text-sm rounded focus:ring-primary leading-tight focus:border-primary-light py-2 px-3 w-full">
						<option value="" selected>Select Device</option>
					</select>

				</div>
				<div class="ml-auto">
					<app-button [style]="'text'" text="Remove"></app-button>
				</div>
			</div>
			<div class="flex gap-3 items-center ">
				<div class="flex-grow">
					<select
						class="block bg-white border-2 border-secondary text-primary text-sm rounded focus:ring-primary leading-tight focus:border-primary-light py-2 px-3 w-full">
						<option value="" selected>Select Device</option>
					</select>
				</div>
				<div class="ml-auto">
					<app-button [style]="'text'" text="Remove"></app-button>
				</div>
			</div>
			<div class="flex gap-3 items-center ">
				<div class="flex-grow">
					<select
						class="block bg-white border-2 border-secondary text-primary text-sm rounded focus:ring-primary leading-tight focus:border-primary-light py-2 px-3 w-full">
						<option value="" selected>Select Device</option>
					</select>

				</div>
				<div class="ml-auto">
					<app-button [style]="'text'" text="Remove"></app-button>
				</div>
			</div>
			<div class="flex gap-3 items-center ">
				<div class="flex-grow">
					<select
						class="block bg-white border-2 border-secondary text-primary text-sm rounded focus:ring-primary leading-tight focus:border-primary-light py-2 px-3 w-full">
						<option value="" selected>Select Device</option>
					</select>
				</div>
				<div class="ml-auto">
					<app-button [style]="'text'" text="Remove"></app-button>
				</div>
			</div>
			<div class="flex gap-3 items-center ">
				<div class="ml-auto">
					<app-button text="Apply"></app-button>
				</div>
			</div>
		</div>
		<div class="flex flex-col gap-2">
			<div class="flex gap-3 items-center ">
				Export options
			</div>
			<div class="flex gap-3 items-center ">
				<div class="flex-grow">
					<select
						class="block bg-white border-2 border-secondary text-primary text-sm rounded focus:ring-primary leading-tight focus:border-primary-light py-2 px-3 w-full">
						<option value="" selected>Select Device</option>
					</select>

				</div>
				<div class="ml-auto">
					<app-button [style]="'text'" text="Remove"></app-button>
				</div>
			</div>
			<div class="flex gap-3 items-center ">
				<div class="ml-auto">
					<app-button text="Download"></app-button>
				</div>
			</div>
		</div>

	</div>
</div>

<div class="flex flex-col gap-2 rounded-lg bg-gray p-3 mb-5">
	<div class="flex gap-3 items-center border-b border-secondary last:border-none pb-2">
		<div>
			<div class="bg-secondary rounded-full w-12 h-12 overflow-hidden">

			</div>
		</div>
		<div class="flex flex-col">
			<div class="text-xs text-primary opacity-50">14:35</div>
			<div>Heizung ist 21.8°C</div>
			<div class="text-xs">in Wohnzimmer</div>
		</div>
		<div class="ml-auto">

		</div>
	</div>
	<div class="flex gap-3 items-center border-b border-secondary last:border-none last:pb-0 pb-2">
		<div>
			<div class="bg-secondary rounded-full w-12 h-12 overflow-hidden">

			</div>
		</div>
		<div class="flex flex-col">
			<div class="text-xs text-primary opacity-50">14:35</div>
			<div>Heizung ist 21.8°C</div>
			<div class="text-xs">in Wohnzimmer</div>
		</div>
		<div class="ml-auto">

		</div>
	</div>

</div>


<app-table [dataSource]="tableData" [tableColumns]="tableColumns"></app-table>

<!-- <app-tabs>
	<app-tab-item label="Tab1">Tab1</app-tab-item>
	<app-tab-item label="Tab1">Tab1</app-tab-item>
	<app-tab-item label="Tab1">Tab1</app-tab-item>
	<app-tab-item label="Tab1">Tab1</app-tab-item>
	<app-tab-item label="Tab1">Tab1</app-tab-item>
</app-tabs>



<div>
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
	Hallo
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
</div>

-->
<table class="w-full text-sm text-left text-primary ">
	<thead class="text-xs text-gray-700 uppercase bg-gray ">
		<tr>
			<th scope="col" class="p-4">
				<div class="flex items-center">
					<input id="checkbox-all" type="checkbox"
						class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 ">
					<label for="checkbox-all" class="sr-only">checkbox</label>
				</div>
			</th>
			<th scope="col" class="px-6 py-3">
				Gateway ID
			</th>
			<th scope="col" class="px-6 py-3">
				Gateway Type
			</th>
			<th scope="col" class="px-6 py-3">
				Created date
			</th>
			<th scope="col" class="px-6 py-3">
				Action
			</th>
		</tr>
	</thead>
	<tbody>
		<tr class="bg-white border-b border-b-secondary hover:bg-secondary">
			<td class="w-4 p-4">
				<div class="flex items-center">
					<input id="checkbox-table-1" type="checkbox"
						class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 ">
					<label for="checkbox-table-1" class="sr-only">checkbox</label>
				</div>
			</td>
			<th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
				5d1de580-642e-4096-9c3f-2636c19a04c8
			</th>
			<td class="px-6 py-4">
				SmartThingsHub
			</td>
			<td class="px-6 py-4">
				2023-06-09
			</td>
			<td class="px-6 py-4">
				<a href="#" class="font-medium hover:underline">Edit</a>
			</td>
		</tr>
		<tr class="bg-white border-b  border-b-secondary hover:bg-secondary">
			<td class="w-4 p-4">
				<div class="flex items-center">
					<input id="checkbox-table-2" type="checkbox"
						class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 ">
					<label for="checkbox-table-2" class="sr-only">checkbox</label>
				</div>
			</td>
			<th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
				5d1de580-642e-4096-9c3f-2636c19a04c8
			</th>
			<td class="px-6 py-4">
				SmartThingsHub
			</td>
			<td class="px-6 py-4">
				2023-06-09
			</td>
			<td class="px-6 py-4">
				<a href="#" class="font-medium hover:underline">Edit</a>
			</td>
		</tr>
	</tbody>
</table>
<!--


<table class="w-full text-sm text-left text-primary ">
	<thead class="text-xs text-gray-700 uppercase bg-gray ">
		<tr>
			<th scope="col" class="p-4">
				#
			</th>
			<th scope="col" class="px-6 py-3">
				Gateway ID
			</th>
			<th scope="col" class="px-6 py-3">
				Gateway Type
			</th>
			<th scope="col" class="px-6 py-3">
				Created date
			</th>
			<th scope="col" class="px-6 py-3">
				Action
			</th>
		</tr>
	</thead>
	<tbody>
		<tr class="bg-white border-b border-b-secondary hover:bg-secondary">
			<td class="w-4 p-4">
				1
			</td>
			<th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
				5d1de580-642e-4096-9c3f-2636c19a04c8
			</th>
			<td class="px-6 py-4">
				SmartThingsHub
			</td>
			<td class="px-6 py-4">
				2023-06-09
			</td>
			<td class="px-6 py-4">
				<a href="#" class="font-medium hover:underline">Edit</a>
			</td>
		</tr>
		<tr class="bg-white border-b  border-b-secondary hover:bg-secondary">
			<td class="w-4 p-4">
				2
			</td>
			<th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
				5d1de580-642e-4096-9c3f-2636c19a04c8
			</th>
			<td class="px-6 py-4">
				SmartThingsHub
			</td>
			<td class="px-6 py-4">
				2023-06-09
			</td>
			<td class="px-6 py-4">
				<a href="#" class="font-medium hover:underline">Edit</a>
			</td>
		</tr>
	</tbody>
</table>


<div class="grid grid-cols-4 gap-4">
	<app-device-widget>
		<div class="flex items-center flow-row">
			<div>
				Humidity
			</div>
			<div class="ml-auto">
				<span class="font-semibold">70</span>
				<span class="font-semibold">%</span>
			</div>
		</div>
		<div class="flex items-center flow-row">
			<div>
				Temperature
			</div>
			<div class="ml-auto">
				<span class="font-semibold">21</span>
				<span class="font-semibold">°C</span>
			</div>
		</div>
	</app-device-widget>
	<app-device-widget>
		<div class="flex items-center flow-row">
			<div>
				Humidity
			</div>
			<div class="ml-auto">
				<span class="font-semibold">70</span>
				<span class="font-semibold">%</span>
			</div>
		</div>
		<div class="flex items-center flow-row">
			<div>
				Temperature
			</div>
			<div class="ml-auto">
				<span class="font-semibold">21</span>
				<span class="font-semibold">°C</span>
			</div>
		</div>
	</app-device-widget>
	<app-device-widget>
		<div class="flex items-center flow-row">
			<div>
				Humidity
			</div>
			<div class="ml-auto">
				<span class="font-semibold">70</span>
				<span class="font-semibold">%</span>
			</div>
		</div>
		<div class="flex items-center flow-row">
			<div>
				Temperature
			</div>
			<div class="ml-auto">
				<span class="font-semibold">21</span>
				<span class="font-semibold">°C</span>
			</div>
		</div>
	</app-device-widget>
	<app-device-widget>
		<div class="flex items-center flow-row">
			<div>ON</div>
			<div class="ml-auto">
				<app-button [style]="'outline'" [isIconLeft]="true" [iconLeft]="['fas', 'power-off']"></app-button>
			</div>
		</div>
	</app-device-widget>
	<app-device-widget>
		<div class="flex items-center flow-row">
			<div>ON</div>
			<div class="ml-auto">
				<app-toggle-switch></app-toggle-switch>
			</div>
		</div>
	</app-device-widget>
</div>


<app-dropdown>
	<ul class="py-1  text-primary">
		<li><a class="block py-2 px-4 rounded text-sm hover:bg-secondary">Test</a></li>
		<li><a class="block py-2 px-4 rounded text-sm hover:bg-secondary">Test</a></li>
		<li><a class="block py-2 px-4 rounded text-sm hover:bg-secondary">Test</a></li>
		<li><a class="block py-2 px-4 rounded text-sm hover:bg-secondary">Test</a></li>
	</ul>

</app-dropdown>

<div class="grid grid-cols-4 gap-4 mt-4">
	<app-chart-widget [chartData]="chartData[0]">
	</app-chart-widget>
	<app-chart-widget [chartData]="chartData[1]">
	</app-chart-widget>
</div>
<app-button (btnClick)="openModal('test')" text="Modal"></app-button>


<app-toggle-switch></app-toggle-switch>
<app-input></app-input>
<app-modal id="test">
	Hallo Welt
</app-modal> -->
<div
	class="relative flex items-center justify-center before:w-[20rem] before:h-[20rem] before:bg-[#FDE8EC] before:-z-10 before:rounded-full before:border-2 before:border-[#FCC9D1] before:absolute after:h-[26rem] after:w-[26rem] after:bg-[#FEF3F5] after:border-2 after:border-[#FDEBED] after:rounded-full after:absolute after:-z-20  w-full h-[26rem]">
	<button [appOnHold]="panic" duration="3000"
		class="appearance-none relative w-52 h-52 bg-[#F71735] rounded-full shadow-[0_19px_21px_rgb(247,23,53,0.33)] shadow-[#FBA5B1] duration-[3000ms] transition-all ease-in-out">
		<div class="flex flex-col text-white h-full w-full items-center justify-center gap-2">
			<h1 class="font-semibold text-5xl uppercase">Panic</h1>
			<p class="font-semibold text-base">Press for 3 seconds</p>
		</div>
	</button>
</div>