import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';


export enum RulerFactoryOption {
	Start = 'START',
	End = 'END',
	Default = 'DEFAULT',
}

export interface NumberedPagination {
	current_page: number;
	last_page: number;
	pages: number[];
}


@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

	@Input() total: number = 1000;
	@Input() current_page: number = 1;
	@Input() per_page: number = 25;
	@Input() per_page_options: number[] = [10, 25, 50, 100];
	@Input() last_page: number = Math.ceil(this.total / this.per_page);

	@Output() page: EventEmitter<number> = new EventEmitter<number>();
	@Output() perPageChanged: EventEmitter<number> = new EventEmitter<number>();

	public from: number;
	public to: number;

	ngOnInit(): void {
		this.from = ((this.current_page - 1) * this.per_page) + 1;
		this.to = this.current_page * this.per_page
	}

	ngOnChanges(changes: any) {
		// console.log('changes', changes);
		this.from = ((this.current_page - 1) * this.per_page) + 1;
		this.to = this.current_page * this.per_page
	}

	get pagination(): NumberedPagination {
		this.last_page = Math.ceil(this.total / this.per_page);
		const { current_page, last_page } = this;
		const pages = this.ruler(current_page, last_page);
		// console.log('pages', pages);
		return { current_page, last_page, pages } as NumberedPagination;
	}

	trackByFn(index: number): number {
		return index;
	}

	navigateToPage(pageNumber: number): void {
		if (this.allowNavigation(pageNumber, this.current_page, this.last_page)) {
			this.current_page = pageNumber;

			this.from = ((this.current_page - 1) * this.per_page) + 1;
			this.to = this.current_page * this.per_page

			this.page.emit(this.current_page);
		}
	}

	ruler(
		currentIndex: number,
		maxPages: number
	): any[] {
		// console.log('currentIndex', currentIndex);
		let pages: any[] = [];
		if (currentIndex === 1 && maxPages === 1) return pages = [1];

		const r1 = currentIndex > 5 && (maxPages - currentIndex) < 1 ? (currentIndex - 4) : ((currentIndex - 2) <= 0 ? currentIndex : (currentIndex - 2));

		// console.log('r1', r1);
		let i;
		for (i = 0; i < 5 && (r1 + i) < maxPages; i++) {
			pages.push(r1 + i);
		}
		// console.log('i', i);

		if ((r1 + i) < maxPages) {
			pages.push('...');
		} else {
			pages.push(maxPages);
		}

		return pages;
	};


	allowNavigation(
		pageNumber: number,
		index: number,
		maxPages: number
	): boolean {
		return pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
	};



	changeItemsPerPage() {
		// console.log('per_page', this.per_page);
		this.perPageChanged.emit(this.per_page);
		this.from = ((this.current_page - 1) * this.per_page) + 1;
		this.to = this.current_page * this.per_page
	}

}
