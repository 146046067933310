


import { Component } from '@angular/core';
import { ModalComponent } from './modal.component';

@Component({
	selector: 'app-modal-body',
	template: `
		<section class="p-5">
			<ng-content></ng-content>
		</section>
	`,
	styleUrls: ['./modal.component.scss']
})
export class ModalBodyComponent {

	constructor(readonly modal: ModalComponent) { }

}
