import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap } from "rxjs";

import { RulesService } from "../../services/rules/rules.service";
import { Rules } from "./rule.actions";
import { Rule } from "../../models/rule/rule.model";

export class RuleStateModel {
	filter: any
	rules: any
}

@State<RuleStateModel>({
	name: 'rulestate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: ''
		},
		rules: []
	}
})

@Injectable()
export class RuleState {
	constructor(private rulesService: RulesService) { }

	@Selector()
	static selectRules(state: RuleStateModel) {
		return state.rules;
	}

	@Selector()
	static getRule(state: RuleStateModel) {
		return (id: number) => {
			return state.rules.find((r: Rule) => {
				return r.id == id
			});
		};
	}

	@Action(Rules.FetchAll)
	getRules(ctx: StateContext<RuleStateModel>) {
		return this.rulesService.fetchRules().pipe(tap((returnData: any) => {
			console.log('fetchRules', returnData);
			const state = ctx.getState();
			ctx.patchState({
				...state,
				rules: returnData.data,
				// filter: {
				// 	...state.filter,
				// 	page: returnData.current_page,
				// 	per_page: returnData.per_page,
				// 	total: returnData.total,
				// 	q: ''
				// }
			});
		}));
	}


}