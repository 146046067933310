

import { Component } from '@angular/core';
import { ModalComponent } from './modal.component';

@Component({
	selector: 'app-modal-header',
	template: `
		<header class="p-5 border-b border-b-secondary flex items-center">
			<div>
				<ng-content></ng-content>
			</div>
			<div class="ml-auto">
				<a *ngIf="!modal.noCloseButton" class="cursor-pointer" (click)="modal.close()"><fa-icon class="stroke-primary text-primary" [icon]="['fas', 'close']"></fa-icon></a>
			</div>
		</header>
	`,
	styleUrls: ['./modal.component.scss']
})
export class ModalHeaderComponent {

	constructor(readonly modal: ModalComponent) { }

}
