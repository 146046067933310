<div class="mb-4">
	<h1 class="font-semibold text-2xl">
		Dashboard
	</h1>
</div>
<div class="mb-8">
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
	<div class="flex flex-row flex-nowrap py-4">
		<div class="basis-1/3">
			<div class="opacity-50">
				Gateways
			</div>
			<div class="text-2xl font-bold">
				{{ organization?.statistics.gateways.total }}
			</div>
		</div>
		<div class="basis-1/3">
			<div class="opacity-50">
				Devices
			</div>
			<div class="text-2xl font-bold">
				{{ organization?.statistics.devices.total }}
			</div>
		</div>
		<div class="basis-1/3">
			<div class="opacity-50">
				Counted events today
			</div>
			<div class="text-2xl font-bold">
				{{ organization?.statistics.events.devices + organization?.statistics.events.gateways }}
			</div>
		</div>
	</div>
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
</div>
<div class="">
	<h3 class="opacity-50">Health</h3>
	<div class="flex flex-row flex-nowrap py-4">
		<div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Gateway online state
				</div>
				<div class="text-2xl font-bold">
					{{ organization?.statistics.gateways.online }} of {{ organization?.statistics.gateways.total }} Gateways are online
				</div>
			</div>
		</div>
		<div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="device_online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Device online state
				</div>
				<div class="text-2xl font-bold">
					{{ organization?.statistics.devices.online }} of {{ organization?.statistics.devices.total }} Devices are online
				</div>
			</div>
		</div>
		<!-- <div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="device_online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Pending Alarms
				</div>
				<div class="text-2xl font-bold">
					210 of 260
				</div>
			</div>
		</div> -->
	</div>
</div>
<div class="my-4">
	<h1>Gateway events</h1>
	<div class="flex items-center justify-between pb-4">
		<div>

		</div>
		<label for="table-search" class="sr-only">Search</label>
		<div class="relative">
			<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
				<fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
			</div>
			<input type="text" id="table-search-users"
				class="block pl-10 w-80 appearance-none border-2 border-secondary rounded py-2 px-3 text-primary leading-tight focus:outline-none focus:border-primary-light"
				placeholder="Search" [(ngModel)]="term" (keyup)="onSearchEvent()" />
		</div>
	</div>
	<app-pagination [total]="($filter | async)?.total" [current_page]="($filter | async)?.page" [per_page]="($filter | async)?.per_page"
		(page)="pageChange($event)" (perPageChanged)="perPageChange($event)"></app-pagination>
	<app-table [dataSource]="$gatewaysEventsTableData" [tableColumns]="gatewaysEventsTableColumns" (sortChange)="sortChanged($event)"></app-table>
	<app-pagination [total]="($filter | async)?.total" [current_page]="($filter | async)?.page" [per_page]="($filter | async)?.per_page"
		(page)="pageChange($event)" (perPageChanged)="perPageChange($event)"></app-pagination>
</div>