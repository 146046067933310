<nav class="flex items-center justify-between py-4" aria-label="Table navigation">
	<div>
		<span class="text-sm font-normal text-gray-500 ">Showing <span class="font-semibold text-gray-900 ">{{ from }} - {{ to }}</span>
			of <span class="font-semibold text-gray-900 ">{{ total }}</span></span>
	</div>
	<div class="flex flex-row flex-nowrap gap-4">
		<div class="flex flex-row">
			<label class="whitespace-nowrap pr-2">Items per page</label>
			<select (change)="changeItemsPerPage()" [(ngModel)]="per_page"
				class="bg-white border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary w-full px-4 h-8">
				<option *ngFor="let item of per_page_options" [value]="item">{{item}}</option>
			</select>
		</div>
		<div>
			<ul class="inline-flex -space-x-px text-sm h-8">
				<li class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border rounded-l hover:bg-secondary"
					(click)="navigateToPage(1)">
					First
				</li>
				<li class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border hover:bg-secondary"
					(click)="navigateToPage(current_page - 1)">
					Previous
				</li>
				<li class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border hover:bg-secondary"
					*ngFor="let page of pagination.pages; trackBy: trackByFn"
					[ngClass]="{'bg-secondary' : (page == pagination.current_page), 'bg-white' : !(page == pagination.current_page)}"
					(click)="navigateToPage(page)">
					{{ page }}
				</li>
				<li class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border hover:bg-secondary"
					(click)="navigateToPage(current_page  + 1)">
					Next
				</li>
				<li class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border rounded-r hover:bg-secondary"
					(click)="navigateToPage(last_page)">
					Last
				</li>
			</ul>
		</div>
	</div>
</nav>