<button *ngIf="!isAnchor" [type]="type" (click)="onClick()" [disabled]="isDisabled" [class]="class">
	<fa-icon *ngIf="isIconLeft" size="xs" [class]="iconClass" [icon]="iconLeft"></fa-icon>
	{{text}}
	<fa-icon *ngIf="isIconRight" size="xs" [class]="iconClass" [icon]="iconRight"></fa-icon>
</button>


<a *ngIf="isAnchor" [routerLink]="link" (click)="onClick()" [class]="class">
	<fa-icon *ngIf="isIconLeft" size="xs" [class]="iconClass" [icon]="iconLeft"></fa-icon>
	{{text}}
	<fa-icon *ngIf="isIconRight" size="xs" [class]="iconClass" [icon]="iconRight"></fa-icon>
</a>