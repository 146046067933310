import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Layouts
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { ButtonComponent } from './components/button/button.component';
import { DeviceWidgetComponent } from './components/device-widget/device-widget.component';
import { ChartWidgetComponent } from './components/chart-widget/chart-widget.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { CdkTableModule } from '@angular/cdk/table';

import { ModalComponent } from './components/modal/modal.component';
import { ModalHeaderComponent } from './components/modal/modal-header.component';
import { ModalFooterComponent } from './components/modal/modal-footer.component';
import { ModalBodyComponent } from './components/modal/modal-body.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { InputComponent } from './components/input/input.component';
import { TabsComponent } from './components/tabs/tabs/tabs.component';
import { TabItemComponent } from './components/tabs/tab-item/tab-item.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { TableComponent } from './components/table/table/table.component';
import { PaginationComponent } from './components/pagination/pagination/pagination.component';
import { SliderComponent } from './components/slider/slider/slider.component';
import { OnHoldDirective } from './directives/on-hold/on-hold.directive';

@NgModule({
	declarations: [
		HeaderComponent,
		SidebarComponent,
		ButtonComponent,
		DeviceWidgetComponent,
		ChartWidgetComponent,
		ModalComponent,
		ModalHeaderComponent,
		ModalBodyComponent,
		ModalFooterComponent,
		DropdownComponent,
		ToggleSwitchComponent,
		InputComponent,
		TabsComponent,
		TabItemComponent,
		IndicatorComponent,
		TableComponent,
		PaginationComponent,
		SliderComponent,
		OnHoldDirective,
	],
	imports: [
		CommonModule,
		RouterModule,
		FontAwesomeModule,
		FormsModule,
		CdkTableModule
	],
	exports: [
		RouterModule,
		HeaderComponent,
		SidebarComponent,
		ButtonComponent,
		DeviceWidgetComponent,
		ChartWidgetComponent,
		ModalComponent,
		ModalHeaderComponent,
		ModalBodyComponent,
		ModalFooterComponent,
		DropdownComponent,
		ToggleSwitchComponent,
		InputComponent,
		TabsComponent,
		TabItemComponent,
		IndicatorComponent,
		TableComponent,
		PaginationComponent,
		SliderComponent,
		OnHoldDirective
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab);
	}
}
