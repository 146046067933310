<div class="antialiased text-primary">


	<app-header></app-header>
	<app-sidebar></app-sidebar>

	<main class="p-4 md:ml-64 h-auto pt-20 relative">
		<xng-breadcrumb></xng-breadcrumb>
		<router-outlet></router-outlet>
	</main>

	<app-modal id="new_version" [noCloseButton]="true" [noCloseOnBackdrop]="true">
		<app-modal-header>New Version available</app-modal-header>
		<app-modal-body>
			A new version is available. Please reload.
		</app-modal-body>
		<app-modal-footer>
			<app-button text="Reload" (btnClick)="reload()"></app-button>
		</app-modal-footer>
	</app-modal>
</div>