import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class OrganizationService {

	constructor(private apiService: ApiService) { }

	fetchOrganizations(options?: any) {
		return this.apiService.get(`${environment.api_base_url}/organizations`, options);
	}

	addOrganization(organization: any, options?: any) {
		return this.apiService.post(`${environment.api_base_url}/organizations`, organization, options);
	}

	// updateOrganization(organization: any, options?: any) {
	// 	return this.apiService.put(`${environment.api_base_url}/organizations`, organization, options);
	// }

	deleteOrganization(id: number, options?: any) {
		return this.apiService.delete(`${environment.api_base_url}/organizations/${id}`, options);
	}


	fetchOrganizationUsers(id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/organizations/${id}/users`, options);
	}


	addOrganizationUser(id: number, user: any, options?: any) {
		return this.apiService.post(`${environment.api_base_url}/organizations/${id}/users`, user, options);
	}

	deleteOrganizationUser(organization_id: number, user_id: number, options?: any) {
		return this.apiService.delete(`${environment.api_base_url}/organizations/${organization_id}/users/${user_id}`, options);
	}

	fetchUserRoles(options?: any) {
		return this.apiService.get(`${environment.api_base_url}/organizations/users/roles`, options)
	}

	resendOrganizationUserInvite(organization_id: number, user_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/organizations/${organization_id}/users/${user_id}/resend`, options)
	}
}