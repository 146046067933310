import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
	@Input() dataSource: any;
	@Input() tableColumns: any;
	@Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

	sortColumns: any;

	sortIcon: any = {
		'default': 'sort',
		'asc': 'sort-up',
		'desc': 'sort-down'
	}

	ngOnInit(): void {
		this.sortColumns = {};

		this.sortColumns = this.tableColumns.filter((c: any) => c.isSortable).reduce((obj: any, item: any) => {
			return {
				...obj,
				[item.columnDef]: 'default'
			}
		}, this.sortColumns);

	}

	getDisplayedColumns(): string[] {
		return this.tableColumns.filter((c: any) => c.show ?? true).map((c: any) => c.columnDef);
	}

	sort(key: string) {
		if (this.sortColumns[key] == 'default') {
			this.sortColumns[key] = 'asc';
		} else if (this.sortColumns[key] == 'asc') {
			this.sortColumns[key] = 'desc';
		} else {
			this.sortColumns[key] = 'default';
		}
		this.sortChange.emit(this.sortColumns);
	}
}
