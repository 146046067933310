import { Component, OnInit } from '@angular/core';

import { AuthService } from './auth.service';
import { WebsocketService } from './shared/services/websocket/websocket.service';

import { Configuration, FrontendApi } from '@ory/kratos-client';
import { Observable, map, tap, catchError, timer } from 'rxjs';

import { HttpHeaders, HttpClient } from '@angular/common/http';

import { ModalService } from './shared/services/modal/modal.service';

import { environment } from '@env/environment';
import { ApiService } from './shared/services/api/api.service';
import { Store } from '@ngxs/store';
import { GatewayDevices } from './shared/state/gateway_devices/gateway_devices.actions';
import { GatewayState } from './shared/state/gateway/gateway.state';
import { Gateway } from './shared/state/gateway/gateway.model';
import { User } from './shared/state/user/user.actions';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	wsMessages$: any;


	constructor(
		private authService: AuthService,
		private wsService: WebsocketService,
		private modalService: ModalService,
		private store: Store
	) { }

	ngOnInit() {

		// Show new version modal test
		// if (environment.production) {
		// 	const pollintervall = 1000; //check every second
		// 	timer(pollintervall, pollintervall).subscribe(() => {

		// 		const options = {
		// 			headers: new HttpHeaders({
		// 				'Cache-Control': 'no-cache'
		// 			})
		// 		}

		// 		this.http.get('assets/version.json', options).subscribe((response: any) => {
		// 			console.log(response.buildVersion, environment.version);
		// 			if (environment.version !== response.buildVersion) {
		// 				this.modalService.open('new_version');
		// 			}
		// 		});
		// 	});
		// }

		this.authService.isAuthenticated$.subscribe(isAuthenticated => {
			console.log('isAuthenticated', isAuthenticated);
			if (!isAuthenticated) {
				this.wsMessages$.unsubscribe();
				this.wsService.close();
				return;
			}

			this.store.dispatch(new User.Fetch);
			this.wsService.connect();
		});

		this.wsMessages$ = this.wsService.$messages.subscribe((message: any) => {
			console.log(message);
			if (message.message) return;
			this.store.dispatch(new GatewayDevices.UpdateDeviceState(
				message.gateway_id,
				message.device_id,
				message.event.component,
				message.event.capability,
				message.event.attribute,
				message.event.value,
				message.event.created_at
			))
		}, (error: any) => { console.log('WS error', error) });


	}

	reload() {
		window.location.reload();
	}

	logout() {
		this.authService.logout()

		const kratosconfig = new Configuration({
			basePath: 'https://auth.iot.aipilot.tech:4433',
			baseOptions: {
				withCredentials: true
			}
		});

		const kratos = new FrontendApi(kratosconfig);

		kratos.createBrowserLogoutFlow().then(({ data: flow }) => {
			console.log('logout flow', flow);
			kratos.updateLogoutFlow({ token: flow.logout_token }).then((data) => {
				console.log('updateLogoutFlow', data);
			});
		});
	}

	reset() {
		this.store.reset(null);
	}
}
